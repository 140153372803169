@use 'node_modules/@inst-iot/bosch-angular-ui-components/styles/global' as * with (
  $rb-extended-breakpoints: true,
  $font-path: '~@inst-iot/bosch-angular-ui-components/assets/'
);

/* $rb-extended-breakpoints: false; */ // whether to use extended breakpoints xxl and xxxl */

@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';


@import url('https://fonts.googleapis.com/css2?family=Codystar&display=swap');

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

rb-full-header {
  .header-content {
    grid-template-columns: 1fr 1fr !important;
  }
  
  .main-nav {
    grid-column: 1/2 !important;
    grid-row: 3/3 !important;
  }
  
  .meta-nav {
    grid-column: 2/2 !important;
    grid-row: 1/3 !important; 
  }
  
  .sub-brand {
    grid-column: 2/2 !important;
    grid-row: 2/3 !important;
    text-align: right;
  }
  
  .action-nav {
    grid-column: 2/2 !important;
    grid-row: 3/3 !important;
    padding-right: 2rem !important;
  }
}

@media(max-width: 768px) {
  rb-full-header {
    .header-content {
      grid-template-columns: 1fr 2fr !important;
    }
    
    .sub-brand {
      display: none;
    }
    
    .action-nav {
      grid-column: 2/2 !important;
      grid-row: 1/1 !important;
      padding-right: 2rem !important;
    }
  }
}

.nav-main,.nav-action {
  nav > a {
    font-size: 1.5rem !important;
    font-weight: 200 !important;
  }
}

.popover-large {
  max-width: 100% !important;
}

.tab-panel {
  margin: 0 0 0 0 !important;
  max-width: 100% !important;
}

.noHr {
  & hr {
    border-top: 0px;
  }
}

.logo {
  padding-left: 1rem;
}

.sub-brand {
  padding-right: 2rem !important;
}

.meta-nav {
  padding-right: 2rem !important;
}

.action-nav {
  padding-right: 2rem !important;
}

.rightButton {
  &div {
    padding-right: 0px !important;
  }
}

footer {
  margin-top: 0 !important;
  flex: 1; /* same as flex-grow: 1; */
  // position: static !important;
  z-index: 1;
}

.breadcrumbs {
  li {
    font-size: 1rem !important;
  }
}

@media (max-width: 600px) {
  footer {
    position: static !important;
    display: block !important;
  }
  
  .footer-placeholder {
    display: none !important;
  }
}

#toast-container>.toast-success {
  background-color: rgba(120, 190, 32, 0.8);
}

#toast-container>.toast-warning {
  background-color: rgba(252, 175, 23, 0.8);
}

#toast-container>.toast-error {
  background-color: rgba(234, 0, 22, 0.8);
}

@media (min-width: 1024px) {
  .popover {
    min-width: 15vw !important;
    max-width: 40vw !important;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}

a {
  text-decoration: none !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.panelClass{
  margin-top: 30px !important; 
}

.cal-week-view .cal-time-events {
  max-height: 60vh;
  overflow-y: auto;
}

.cal-day-headers {
  margin-right: 14.5px;
}

.cal-day-columns {
  height: 100%;
}